@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import 'colors';

.better_maps {
    font-family: Roboto, sans-serif;
    .top_bar {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.1px solid $grey;
        opacity: 1;
        top: 0;
        width: 100%;
        position: absolute;
        z-index: 10;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        .brand_logo {
            letter-spacing: 0px;
            color: $base-color;
            opacity: 1;
            font-size: 40px;
            font-variant: normal;
            font-weight: 500;
            margin: 11px 7px 0px 7px;
            .brand_logo_text {
                font-weight: 200;
            }
            img {
                max-width: 200px;
            }
        }

        .sign_out {
            margin-left:auto;
            height: 50px;
        }
        .SignOutIcon {
            height: 30px;
            width: 30px;
        }
        .user_avatar {
            background: $base-color 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            opacity: 1;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white-bg;
            font: normal normal normal 24px/30px Roboto;
            letter-spacing: 0px;
            opacity: 1;
            margin: 5px 5px 10px 10px;
        }
    }
    .side_bar {
        .sidenav---sidenav---_2tBP {
            background: $base-color;
            margin-top: 50px;
        }
        svg {
            background-size: 26px 20px;
        }
    }

}