@import "colors";
@import "layout";
@import "create-page";
@import "common";
@import "edit-page";
@import "typography";
@import "rheostat";
@import "analyse-page";
@import "account-page";
@import "bettermaps-dropzone";
body {
  font-family: Roboto, sans-serif;
}

:root {
  --amplify-primary-color: #006837;
  --amplify-primary-tint: #00bb64be;
  --amplify-primary-shade: #00683788;
}

amplify-container {
  background: #006837;
  padding: 0px;
}

body {
  margin: 0px;
}

:root {
  --main-bg-color: #006837;
  --secondary-bg-color: #d3d3d3;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.control-panel {
  position: absolute;
  top: 50px;
  // margin-top: 100px;
  // left: 60px;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 12px 24px;
  margin: 16px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
  border-radius: 8px;
}
// .overlays { pointer-events:none }

.amplify-button[data-variation="primary"] {
  background-color: #006837 !important;
  border-color: #006837 !important;
}

.amplify-button[data-size="small"] {
  color: #006837 !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 0 0 10px 10px;
  border: none;
  box-shadow: none;
}

.amplify-tabs-item[data-state="active"] {
  border-color: #006837;
  color: #006837;
}
