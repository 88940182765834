@import 'colors';

.create_container {
    .create_card_body {
        display: flex;
        justify-content: space-evenly;

        img {
            width: 188px;
            height: 188px;
            border: 1px solid $grey;
            opacity: 1;
        }
    }
}

.creator_card {

    width:90%;
    max-width:750px;
    margin:0 auto;
    background-color: lightgrey;
    padding: 10px;
    border-radius: 8px;

    .form_column_first_print {
        flex-direction: column;
        align-items: flex-start !important;
        margin-left: 20px;
       
        
        .size_drop_down {
            width: auto !important;
        }
       
        .size_wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 35px;
            .size_input_container {
                display: flex;
                width: 100%;
                justify-content: space-around;
                align-items: center;
                
                input {
                    width: 75px !important;
                    height: 35px !important;
                    margin-left: 0px !important;
                }
    
                span {
                    text-align: left;
                    font: normal normal normal 20px/26px Roboto;
                    letter-spacing: 0px;
                    color: $base-color;
                    opacity: 1;
                }
            }
        }
        
    }

    .close_button {
        display: flex;
        justify-content: flex-end;
        color: #006837;
        font-size: 30px;
        margin: 21px 35px 0px 0px;
        cursor: pointer;
    }

    .title_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title_text {
            font-size: 30px;
            letter-spacing: 0px;
            color: $base-color;
            opacity: 1;
            font-weight: 500;
        }

        .subtitle_container {
            .id_text_chip {
                cursor: pointer;
                font-size: 16px;
                letter-spacing: 0px;
                color: $base-color;
                opacity: 1;
                font-weight: 300;
                width: 188px;
                height: 35px;
                background-color: $white-bg;
                border: 1px solid #707070;
                border-radius: 8px;
                opacity: 1;
                padding: 10px;
                margin-left: 10px;
            }
        }
    }

    .template_section {
        display: flex;
        justify-content: space-around;
        margin-top: 25px;
    }

    .form_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .form_row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .form_column_first {
                display: flex;
                align-items: center;
                text-align: left;
                margin-left: 6px;

                .select-contianer {
                    margin-left: 25px;
                }

                .drop_down {
                    text-align: left;
                    font: normal normal normal 16px/21px Roboto;
                    letter-spacing: 0px;
                    color: $base-color;
                    opacity: 1;
                    width: 100%;
                    height: 35px;
                    border-radius: 8px;
                }

                .input_field {
                    background: $white-bg 0% 0% no-repeat padding-box;
                    border: 1px solid $grey;
                    border-radius: 8px;
                    width: 188px;
                    height: 35px;
                    margin-left: 70px;
                }
            }

            .color_scheme_block {
                height: 32px;
                width: 32px;
                opacity: 0.54;
                margin-left: 5px;
                border-radius: 5px;

            }

            .form_column_second {
                margin-right: 0px;
                display: flex;
                align-items: center;

                .print_img {
                    height: 309px;
                    width: 309px;
                    object-fit: cover;
                }

                .subtitle_text {
                    margin-left: 56px;
                }

                .color_scheme_block {
                    height: 32px;
                    width: 32px;
                    opacity: 0.54;
                    margin-left: 5px;
                    border-radius: 5px;
                }

                .zoom_text {
                    margin-right: 10px;
                }
            }
        }


    }

    .bottom_row {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: 35px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .robo_mic_button {
        margin-left: 5px;
        background-color: $base-color;
        border-radius: 8px;
    }
}

.templates_card {
    background-color: lightgrey;
    margin-left: 200px;
    margin-right: 200px;
    .close_button {
        display: flex;
        justify-content: flex-end;
        color: #006837;
        font-size: 30px;
        margin: 21px 35px 0px 0px;
        cursor: pointer;
    }

    .title_bar {
        margin: 21px 35px 15px 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title_text {
            font-size: 30px;
            letter-spacing: 0px;
            color: $base-color;
            opacity: 1;
            font-weight: 500;
        }
    }

    .templates_container {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;

        .template {
            width: 201px;
            height: 241px;
            background: $white-bg 0% 0% no-repeat padding-box;
            border: 1px solid $grey;
            border-radius: 8px;
            opacity: 1;
            margin: 15px;
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }

        img {
            width: 190px;
            height: 187px;
            object-fit: cover;
            border: 1px solid $grey;
        }

        span {
            font-size: 20px;
            letter-spacing: 0px;
            color: $base-color;
            opacity: 1;
        }
    }
}

.color_scheme_block.selected {
    border: 4px solid #003c20;
}

.ReactModal__Content {
    border: none !important;
}

.ReactModal__Content--after-open {
    border: none !important;
}
