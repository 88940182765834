.Dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    color: var(--primary);
    font-size: 36px;
}

.Dropzone #dropzone-wrapper {
    max-height: 600px;
    width: 600px;
    border: 8px solid var(--primary);
    border-radius: 17px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Dropzone .react-dropzone {
    height: 466px;
    width: 494px;
    border: 4px solid var(--primary);
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-top: 42px;
    text-align: center;
}

.floating-logo {
    background-color: white;
    width: 260px;
    height: 65px;
    top: -53px;
    left: 45px;
    position: absolute;
    padding: 10px;
}

.Dropzone .email {
    border: 1px solid var(--primary);
    border-radius: 8px;
    background-color: #D8D8D8;
    width: 500px;
    height: 44px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: var(--primary);
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.Dropzone .disclaimer {
    color: var(--primary);
    font-size: 12px;
}

#style-tester-result {
    padding-bottom: 80px;
}