.subtitle_text {
    font-size: 20px;
    letter-spacing: 0px;
    color: $base-color;
    opacity: 1;
    font-weight: 400;
}

.subtitle_text {
    font-size: 16px;
    letter-spacing: 0px;
    color: $base-color;
    opacity: 1;
    font-weight: 400;
}