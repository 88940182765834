/* add css module styles here (optional) */

.test {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
}

.feedback_main {
  display: flex;
  justify-content: center;
  width: 320px;
  height: 187px;
  padding: 5px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0px 10px 0 rgba(0, 0, 0, 0.4);
  right: 0;
  bottom: 0;
}

.feedback_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.feedback_title {
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  display: flex;
  align-items: center;
}

.feedback_items {
  width: 300px;
  height: 120px;
  display: flex;
  flex-direction: row;
}

.feedback_item {
  width: 90%;
  background-color: red;
  flex-direction: row;
  margin: 5px;
  border-radius: 20px;
  background-color: #f7fafc;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #3c3737;
  cursor: pointer;
}

.feedback_item:hover {
  background-color: #eaeaeb;
}

.feedback_item:active {
  background-color: #d0cccc;
  box-shadow: 0 0 0 white;
  border: 0px solid #41a0ff;
}

.feedback_wrapper_issues {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedback_wrapper_issues_main {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mymodal {
  position: fixed;
  bottom: 70px;
  right: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 20px;
  outline: none;

  animation: modalSlideX 0.5s ease-out forwards;
  transform: translateY(0);
}
@keyframes modalSlideX {
  from {
    transform: translateX(20%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 15px;
}
