@import 'colors';

.pointer_hover:hover{
    cursor: pointer;
}

.primary_button {
    font: normal normal normal 20px/25px Roboto;
    letter-spacing: 0px;
    color: $base_color;
    opacity: 1;
    border: 1px solid $grey;
    border-radius: 8px;
    width: 188px;
    height: 40px;
    margin-top: 5px;
    font-weight: 400;
    cursor:pointer;
}

.button_container {
    border-radius: 8px;
}

.card {
    background-color: $card-bg;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    border-radius: 10px;
    .card_title {
        margin-top: 45px;
        margin-bottom: 45px;
        display:flex;
        justify-content: center;
        font-size: 30px;
        letter-spacing: 0px;
        color: $base-color;
        opacity: 1;
        font-weight: 500;
    }
    .card_body {
        overflow-y: scroll;
        scroll-behavior: smooth;
        overflow: hidden;
    }
}

// .lg {
//     width: 1060px;
//     height: 40vw;
// }

.md {
    height: 442px;
width: 651px;
}

.account {
    width: 590px;
    height: 340px;
}


